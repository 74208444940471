/* LoginForm.css */
.login-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f7f9fc;
  padding: 20px;
}

.login-form {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.login-form h2 {
  margin-bottom: 20px;
  color: #333;
}

.login-form label {
  display: block;
  text-align: left;
  margin-bottom: 5px;
  color: #555;
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.login-form button:hover {
  background-color: #0056b3;
}

.login-form .error {
  color: red;
  margin-bottom: 15px;
}

.login-form a {
  display: block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
}

.login-form a:hover {
  text-decoration: underline;
}

/* RecuperaPassword.css */
.recupera-password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f7f9fc;
  padding: 20px;
}

.recupera-password-form {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.recupera-password-form h2 {
  margin-bottom: 20px;
  color: #333;
}

.recupera-password-form label {
  display: block;
  text-align: left;
  margin-bottom: 5px;
  color: #555;
}

.recupera-password-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.recupera-password-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.recupera-password-form button:hover {
  background-color: #0056b3;
}

.recupera-password-form .error {
  color: red;
  margin-bottom: 15px;
}

.recupera-password-form .message {
  color: green;
  margin-bottom: 15px;
}

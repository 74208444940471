/* Color System */
:root {
  --primary-color: #27997f; /* Rosso terra, meno intenso e più sofisticato */
  --secondary-color: #2c8a9b; /* Blu moderato per complementare il rosso */
  --background-color: #ffffff; /* Sfondo bianco per mantenere la luminosità */
  --text-color: #2c3e50; /* Grigio bluastro scuro per il testo, per un contrasto leggibile */
  --accent-color: #f1c40f; /* Giallo mostarda per accenti vivaci */
  --success-color: #27ae60; /* Verde calmo per successo */
  --error-color: #c0392b; /* Rosso più scuro per errori */
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: "Roboto", sans-serif; /* Font moderno e pulito */
}

.input-with-dropdown {
  position: relative;
}
.annullato {
  color: red;
  font-weight: bold;
}

.dropdown {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background: #f0f0f0;
}

.button-like {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  background-color: #e7e7e7;
  cursor: pointer;
}

.button-like:hover {
  background-color: #d7d7d7;
}

/* Button Styles */
.btn,
.btn-piatti,
button[type="submit"] {
  background-color: var(--secondary-color);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  margin-right: 12px;
  margin-bottom: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn,
.btn-piatti-2,
button[type="submit"] {
  background-color: var(--accent-color);
  margin-left: 16px;
  color: white;
  border: none;
  border-radius: 4px;
  margin-right: 12px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-delete {
  background-color: rgb(226, 103, 103);
  color: white;
}

.button-wa {
  display: flex;
  align-items: center;
  background-color: var(--primary-color); /* Colore verde WhatsApp */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.button-wa svg {
  margin-right: 10px; /* Distanza tra l'icona e il testo */
}

.button-wa i {
  margin-right: 10px; /* Distanza tra l'icona e il testo */
}

.info-box {
  position: absolute;
  top: 20px;
  right: 260px;
  border: 2px solid var(--accent-color);
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.button-cancel {
  display: flex;
  align-items: center;
  background-color: var(--accent-color); /* Colore verde WhatsApp */
  color: white;
  padding: 10px 20px;
  margin-top: 1vh;
  border: none;
  border-radius: 5px;
  margin-bottom: 16px;
  font-size: 16px;
  cursor: pointer;
}

.btn:hover,
.btn-piatti:hover,
button[type="submit"]:hover {
  background-color: var(--accent-color);
}

/* Alert System */
.alert-success {
  background-color: var(--success-color);
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.alert-error {
  background-color: var(--error-color);
  color: white;
  padding: 10px;
  border-radius: 5px;
}

/* Navbar Styles */
/* Navbar Styles */
.navbar {
  width: 250px; /* Larghezza fissa della sidebar */
  background-color: #dee8e3; /* Colore di sfondo della sidebar */
  height: 100vh; /* Altezza completa per coprire tutto il lato verticale */
  position: fixed; /* Posizione fissa */
  left: 0; /* Allinea a sinistra */
  top: 0; /* Allinea in alto */
  overflow: auto; /* Gestisci lo scorrimento se gli elementi eccedono l'altezza della schermata */
  padding-top: 20px; /* Padding superiore */
  transition: width 0.3s; /* Aggiungi transizione per larghezza */
}

.navbar.collapsed {
  width: 90px; /* Larghezza ridotta per la sidebar compressa */
}

.navbar h2 {
  padding-left: 16px;
  color: var(--primary-color);
  margin-bottom: 20px; /* Spazio sotto il titolo */
  display: none; /* Nascondi il titolo per la sidebar compressa */
}

.navbar.expanded h2 {
  display: block; /* Mostra il titolo per la sidebar espansa */
}

.navbar ul {
  list-style-type: none; /* Rimuovi gli indicatori di lista */
  padding: 0; /* Rimuovi padding interno */
}

.navbar ul li {
  padding: 10px; /* Padding per ogni voce di lista */
  border-bottom: 1px solid #ffffff; /* Separatore tra le voci */
}

.navbar ul li a {
  text-decoration: none; /* Rimuovi la sottolineatura */
  color: var(--text-color); /* Colore del testo */
  display: flex; /* Layout flexibile per icone e testo */
  align-items: center; /* Allinea verticalmente icone e testo */
  margin-bottom: 2vh;
  font-family: "Merge One", sans-serif;
  color: var(--text-color);
  padding: 8px 0px 0px 0px;
}

.navbar ul li a svg {
  margin-right: 15px; /* Aumentato il margine a destra per distanziare l'icona dal testo */
  font-size: 1.2em; /* Opzionale: Aumenta la dimensione dell'icona */
  padding-left: 16px;
}

.navbar ul li a:hover {
  color: white; /* Cambia il colore del testo al hover */
  background-color: var(
    --secondary-color
  ); /* Aggiungi un background color al hover per migliorare il feedback visivo */
}

.navbar ul li a:hover,
.navbar ul li a.active {
  background-color: #2c8a9b; /* Colore di sfondo per elemento attivo o al hover */
  padding: 16px 0px;
}

/* Icon Styles */
.navbar ul li a i {
  margin-right: 10px; /* Spazio tra icona e testo */
}

/* Specific styles for active link, it requires additional handling to add 'active' class on the current route */
.navbar ul li a.active {
  font-weight: bold; /* Testo in grassetto per l'elemento attivo */
  color: white; /* Testo bianco per miglior contrasto */
  padding: 16px 0px;
}

/* Stile per il pulsante di toggle */
.toggle-btn {
  cursor: pointer;
  padding: 10px;
  text-align: right;
  font-size: 1.2em;
}

.navbar.collapsed .toggle-btn {
  text-align: center; /* Allinea al centro il pulsante di toggle per la sidebar compressa */
}

.navbar.collapsed ul li a span {
  display: none; /* Nascondi il testo delle voci di menu per la sidebar compressa */
}

/* Specific styles for active link, it requires additional handling to add 'active' class on the current route */
.navbar ul li a.active {
  font-weight: bold; /* Testo in grassetto per l'elemento attivo */
  color: white; /* Testo bianco per miglior contrasto */
  padding: 16px 0px;
}

/* Form Styling */
.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

th {
  background-color: var(--primary-color);
  color: white;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

/*Form di login*/
.login-container {
  width: 300px;
  margin: 100px auto;
  padding: 20px;
  border: 1px solid #333; /* Grigio scuro */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input[type="email"],
.form-group input[type="password"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: var(--secondary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button[type="submit"]:hover {
  background-color: #444;
}

.error {
  color: red;
  margin-top: 10px;
}

/* ! Calendario System */
.calendario-menu .rbc-calendar {
  margin-top: 3vh;
  width: 70vw !important; /* Imposta la larghezza massima per utilizzare tutto lo spazio disponibile */
  border: 1px solid #f6f6f6; /* Aggiunge un bordo sottile per un look minimal */
  font-family: "Arial", sans-serif; /* Utilizza un font semplice e leggibile */
  height: 90vh !important;
}

.rbc-header {
  background-color: var(
    --background-color
  ); /* Colore di sfondo per le intestazioni del calendario */
  color: var(--text-color); /* Colore del testo per le intestazioni */
}

.rbc-today {
  background-color: var(
    --accent-color
  ); /* Colore di sfondo per il giorno corrente */
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  padding: 8px 5px;
  background-color: var(--secondary-color) !important;
}

.rbc-event {
  background-color: var(
    --secondary-color
  ); /* Colore di sfondo per gli eventi */
  color: white; /* Colore del testo per gli eventi */
  padding: 4px; /* Spaziatura interna per gli eventi */
  border: none; /* Rimuove il bordo dagli eventi */
}

/* ! Font System */
h1,
h2,
h3 {
  color: var(--primary-color);
  font-family: "Merge One", sans-serif;
}

p {
  color: var(--primary-color);
  font-family: "Lato", sans-serif;
}

/* ! Grid System */
/*----------------------------------------------------------------------------------- */
.grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}
.grid--small {
  max-width: 900px;
}
.grid--center {
  justify-content: center;
}
.grid--full {
  max-width: 100%;
}

.col-20 {
  width: 20%;
}
.col-25 {
  width: 25%;
}
.col-30 {
  width: 30%;
}
.col-33 {
  width: 33.33%;
}
.col-40 {
  width: 40%;
}
.col-50 {
  width: 50%;
}
.col-60 {
  width: 60%;
}
.col-70 {
  width: 70%;
}
.col-80 {
  width: 80%;
}
.col-90 {
  width: 90%;
}
.col-100 {
  width: 100%;
}

[class*="col-"] {
  padding: 10px;
}

/*Impostazioni css*/
.settings-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Due colonne */
  gap: 20px;
  padding: 20px;
}

.setting-item {
  background: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.setting-item h2 {
  color: var(--secondary-color);
}

.setting-item p {
  color: var(--text-color);
  margin-bottom: 20px;
}

.setting-item button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.setting-item button:hover {
  background-color: var(--success-color);
}

/* Stili per il calendario */
/* Stili per il calendario */
.rbc-calendar {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.rbc-toolbar {
  margin-bottom: 10px;
}

.rbc-btn-group {
  display: flex;
  justify-content: space-between;
}

.rbc-event {
  border-radius: 4px;
  padding: 5px;
}

.rbc-event.event-pranzo {
  background-color: #b3e5fc; /* Azzurro pastello */
  border-left: 5px solid #81d4fa;
}

.rbc-event.event-cena {
  background-color: #ffcdd2; /* Rosso pastello */
  border-left: 5px solid #ef9a9a;
}

.rbc-toolbar button {
  color: #fff !important;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: #27997f !important;
  background-image: none;
  border: 1px solid #ffffff !important;
  padding: 0.375rem 1rem;
  border-radius: 15px !important;
  line-height: normal;
  white-space: nowrap;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: none !important;
  background-color: #fff !important;
  border-color: #fff !important;
  border: 1px solid #27997f !important;
  color: #27997f !important;
}

.event-pranzo {
  background-color: #99d8c9 !important; /* Azzurro pastello */
  border-color: #99d8c9 !important;
}

.event-cena {
  background-color: #075d49 !important; /* Verde scuro */
  border-color: #075d49 !important;
}

.calendario-menu .rbc-calendar {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.calendario-menu .rbc-event {
  border-radius: 4px;
  padding: 4px;
  color: #fff; /* Cambia il colore del testo se necessario */
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  flex: 0; /* Nascondi il modale inizialmente */
  display: none; /* Nascondi il modale inizialmente */
  transition: flex 0.3s ease, display 0.3s ease;
}

.modal-container.visible {
  flex: 1; /* Espandi il modale quando è visibile */
  display: block; /* Mostra il modale quando è visibile */
}

.modal-content {
  position: fixed;
  top: 0;
  border: 1px solid #e9e9e9;
  right: 0;
  bottom: 0;
  background: white;
  margin-top: 64px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
}

select {
  border-radius: 10px;
  padding: 5px;
}

.select-piatti {
  margin-bottom: 24px;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 8px;
  padding: 20px;
  z-index: 1000;
  max-width: 500px;
  width: 90%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

.modal-form-group {
  margin-bottom: 10px;
}

.modal-button {
  display: inline-block;
  margin-right: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-button:hover {
  background-color: #0056b3;
}

/* style.css */

/* Stile per il form */
.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.form-container input,
.form-container select,
.form-container button {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
}

.form-container input[type="submit"] {
  background-color: #27997f;
  color: white;
  cursor: pointer;
}

.form-container input[type="submit"]:hover {
  background-color: #45a049;
}

/* Aggiungi questa parte al tuo file style.css */

/* Aggiungi questa parte al tuo file style.css */

/* Aggiungi questa parte al tuo file style.css */

/* Aggiungi questa parte al tuo file style.css */

/* Aggiungi questa parte al tuo file style.css */
.gestione-prenotazione {
  margin-left: -4px !important;
}

.search-bar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.search-bar input {
  padding: 10px;
  font-size: 16px;
}

.date-picker {
  display: flex;
  gap: 10px;
  align-items: center;
}

.date-picker label {
  font-size: 16px;
}

.date-picker input {
  padding: 10px;
  font-size: 16px;
}

.results {
  margin-top: 20px;
}

.report-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.report-item {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  flex: 1 1 45%;
  box-sizing: border-box;
}

.report-item.full-width {
  flex: 1 1 100%;
}

.report-item h2 {
  margin-bottom: 10px;
}

.report-item p {
  font-size: 24px;
  font-weight: bold;
}

.report-item ul {
  list-style: none;
  padding: 0;
}

.report-item ul li {
  font-size: 14px;
  margin: 5px 0;
}

.canvas-container {
  height: 400px;
  width: 100%;
}

.tavoli-container {
  display: flex;
  flex-wrap: wrap;
}

.tavolo {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  text-align: center;
  width: 100px;
}

@media (max-width: 600px) {
  .form-container {
    padding: 0.5rem;
  }
}

/* Media Query for Smaller Screens */
@media (max-width: 768px) {
  [class*="col-"] {
    width: 100%;
  }
  .sma-33 {
    width: 33.33%;
  }
  .sma-50 {
    width: 50%;
  }
  .sma-25 {
    width: 25%;
  }
}

/* Main Content and Table Styles */
.main-content {
  padding: 20px;
  margin-left: 22rem;

  justify-content: space-between;
}

.main-content h1 {
  width: 100%;
  margin-bottom: 20px; /* aggiunge uno spazio sotto l'h1 */
}

.table-container {
  flex: 1; /* Default width */
  transition: flex 0.3s ease;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid #ccc;
}

th,
td {
  padding: 10px;
  text-align: left;
  font-family: "Lato", sans-serif;
}

th {
  background-color: var(--primary-color);
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* ! Helpers */
/*----------------------------------------------------------------------------------- */

.br {
  border: 1px solid red;
}
.img-res {
  width: 100%;
}
.video-res {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.video-res video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.overflow {
  overflow: hidden;
}

.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}
.mt-4 {
  margin-top: 4rem;
}

.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.mb-4 {
  margin-bottom: 4rem;
}

.ml-0 {
  margin-left: 0;
}
.ml-1 {
  margin-left: 1rem;
}
.ml-2 {
  margin-left: 2rem;
}
.ml-3 {
  margin-left: 3rem;
}
.ml-4 {
  margin-left: 4rem;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.p-0 {
  padding: 0;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.p-4 {
  padding: 4rem;
}

.pt-0 {
  padding-top: 0;
}
.pt-1 {
  padding-top: 1rem;
}
.pt-2 {
  padding-top: 2rem;
}
.pt-3 {
  padding-top: 3rem;
}
.pt-4 {
  padding-top: 4rem;
}

.pb-0 {
  padding-bottom: 0;
}
.pb-1 {
  padding-bottom: 1rem;
}
.pb-2 {
  padding-bottom: 2rem;
}
.pb-3 {
  padding-bottom: 3rem;
}
.pb-4 {
  padding-bottom: 4rem;
}

.center {
  text-align: center;
}

/* Stile per il background del modale */
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Sfondo semitrasparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Assicura che il modale sia sopra gli altri elementi */
}

/* Stile per il backdrop del modale */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Stile per il contenuto del modale */
.modal {
  background: var(--background-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  z-index: 1050;
}

.modal-content {
  background: white;
  padding: 20px;
  overflow: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%; /* O adatta a seconda delle esigenze */
  max-width: 600px; /* Massima larghezza del modale */
}

/* Gruppo di form nel modale */
.modal-form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.modal-form-label {
  font-size: 18px;
  color: var(--text-color);
  margin-bottom: 5px;
}

.modal header {
  font-size: 26px;
  color: var(--secondary-color);
  margin-bottom: 20px;
}

.modal-form-input {
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-button {
  width: 100%;
  padding: 14px;
  margin-top: 10px;
  background-color: var(--secondary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
}

.modal-button:hover {
  background-color: var(--primary-color);
}

.modal-form-group input[type="checkbox"] {
  margin-right: 10px;
}

.arrow-week {
  margin-right: 10px;
  margin-bottom: 4vh;
  background-color: #dee8e3;
  border: none;
  padding: 10px;
}

/* Stile per la pagina di login */
.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 12px;
}

.login-page h1 {
  font-size: 24px; /* Dimensione del font per il titolo */
  margin-bottom: 20px; /* Margine inferiore per separare dal form */
}

.login-page form {
  display: flex;
  flex-direction: column;
  width: 90%; /* Larghezza adattiva per il form */
  max-width: 400px; /* Massima larghezza per il form */
}

.login-page li {
  list-style: none;
  text-align: center;
}

.login-page input[type="text"],
.login-page button {
  padding: 12px;
  margin-bottom: 12px;
  border-radius: 5px;
  border: 1px solid #ccc;
  text-align: center;
  font-size: 18px; /* Aumenta la dimensione del font */
}

.login-page button {
  background-color: var(--secondary-color);
  color: white;
  cursor: pointer;
  margin: 0 auto;
  margin-bottom: 2vh;
  font-size: 18px; /* Aumenta la dimensione del font */
}

.login-page button:hover {
  background-color: var(--primary-color);
}

.grid {
  display: block !important;
}

.mb-1 {
  margin-bottom: 1vh;
}

/* Custom */
/* Contenitore del form */
.aggiungi-piatto {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-width: 800px;
}

.compilation-form {
  margin-top: 28px !important;
  background: #dee8e3;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
}

div[data-category] {
  margin-top: 20px;
}

div[data-dish] {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

div[data-dish] span {
  margin: 0 10px;
  min-width: 20px;
  text-align: center;
}

div[data-dish] button {
  background-color: #e0e0e0;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

button[type="submit"] {
  width: 100%;
  background-color: #6200ea;
  color: white;
  border: none;
  padding: 12px;
  font-size: 18px;
  margin-top: 20px;
  border-radius: 4px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #3700b3;
}

.quantita-control {
  display: flex;
  align-items: center;
  background-color: white;
  margin-right: 8px;
  padding: 5px;
  border-radius: 4px;
}

.quantita-control button {
  color: #333;
  background-color: transparent;
  border: none;
  padding: 5px 10px;
  font-size: 21px;
  font-weight: 600;
}

.nome-piatto {
  flex-grow: 1;
  margin-right: 8px; /* Distacco tra il nome del piatto e i controlli della quantità */
}

.prezzo {
  margin-left: auto;
  white-space: nowrap; /* Assicura che il prezzo non vada a capo */
}

.selezione-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #dee8e3;
  border-radius: 8px;
}

/* style-modal.css */
.menu-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 80%;
  max-width: 500px;
  max-height: 80%;
  overflow-y: auto;
}

.menu-modal h2 {
  margin-top: 0;
}

.menu-modal ul {
  list-style: none;
  padding: 0;
}

.menu-modal li {
  margin-bottom: 10px;
}

.menu-modal .extra {
  color: red;
  font-weight: bold;
}

.menu-modal button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.menu-modal button:hover {
  background-color: #0056b3;
}

/* Media Queries per vari breakpoint */
@media (max-width: 768px) {
  .login-page form {
    width: 90%; /* Aumenta la larghezza in viewport più piccoli */
  }

  .login-page h1 {
    font-size: 64px; /* Aumenta la dimensione del font per il titolo */
  }

  h1 {
    font-size: 64px; /* Aumenta la dimensione del font per il titolo */
  }

  h1 {
    font-size: 48px; /* Aumenta la dimensione del font per il titolo */
  }

  li {
    list-style: none;
    text-align: center;
    font-size: 36px;
  }

  .selezione-menu {
    align-items: center;
    background-color: #dee8e3;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 24px;
  }

  .login-page form {
    width: 100%; /* Usa l'intera larghezza disponibile */
  }

  .login-page input[type="text"],
  .login-page button {
    padding: 16px; /* Aumenta il padding per gestire meglio lo spazio */
    font-size: 20px; /* Aumenta la dimensione del font */
  }

  .login-page li {
    list-style: none;
    text-align: center;
    font-size: 36px;
  }

  .login-page button,
  .login-page input[type="text"] {
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 48px;
    margin-bottom: 12px;
    padding: 12px;
    text-align: center;
  }

  button[type="submit"] {
    background-color: #6200ea;
    color: #fff;
    font-size: 18px;
    margin-top: 20px;
    padding: 12px;
    width: 100%;
    font-size: 48px;
  }
}

@media (max-width: 480px) {
  .login-page {
    padding: 20px; /* Riduci il padding in viewport molto piccoli */
  }

  .login-page h1 {
    font-size: 28px; /* Aumenta la dimensione del font per il titolo */
  }

  .login-page form {
    width: 100%; /* Usa l'intera larghezza disponibile */
  }

  .login-page input[type="text"],
  .login-page button {
    padding: 16px; /* Aumenta il padding per gestire meglio lo spazio */
    font-size: 20px; /* Aumenta la dimensione del font */
  }
}
